import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import './styles.scss'

const SingleSearchResult = loadable(() =>
    import('/src/components/Search/SearchResults/SingleSearchResult')
)

const SearchResultsDisplay = ({
    type,
    postTypeCategoryKey,
    searchTerm,
    data,
    setSearchResultsInDom
}) => {
    useEffect(() => {
        setSearchResultsInDom(true)
    }, [setSearchResultsInDom])

    return (
        <ul className="c-search-results-display">
            {data.map((node) => {
                const post = {
                    uri: node.uri,
                    title: node.title,
                    description:
                        type === 'category'
                            ? node.seo?.metaDesc
                            : type === 'search'
                            ? node.description
                            : null,
                    category: {
                        image:
                            type === 'category'
                                ? node[postTypeCategoryKey]?.nodes[0].category
                                      .featuredImage
                                : type === 'search'
                                ? node.categoryImage
                                : null,
                        name:
                            type === 'category'
                                ? node[postTypeCategoryKey]?.nodes[0].name
                                : type === 'search'
                                ? node.categoryName
                                : null,
                        link:
                            type === 'category'
                                ? node[postTypeCategoryKey]?.nodes[0].link
                                : type === 'search'
                                ? node.categoryLink
                                : null
                    }
                }

                return (
                    <SingleSearchResult
                        key={node.id}
                        searchTerm={searchTerm}
                        data={post}
                    />
                )
            })}
        </ul>
    )
}

SearchResultsDisplay.propTypes = {
    type: PropTypes.oneOf(['search', 'category']).isRequired,
    postTypeCategoryKey: PropTypes.string,
    searchTerm: PropTypes.string,
    data: PropTypes.array.isRequired,
    setSearchResultsInDom: PropTypes.func
}

SearchResultsDisplay.defaultProps = {
    itemsRepeater: []
}

export default SearchResultsDisplay
